import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import {
    GET_STATUS_PDPA,
    HEADER_TOKEN,
    OIE_COMPANY_LIST,
    OIE_EXPORT_COMPANY_APPROVE,
    TOKEN_BLOB,
    UPDATE_STATUS_REQUEST_PDPA,
} from "../router";
import Axios from "axios";
import Modals from "../Modals";
import Swal from "sweetalert2";
import OiePagination from "../OiePagination";
import { Button, Container, Divider, Grid, Header, Icon, Input, Label, Menu } from "semantic-ui-react";
import { debounce } from "debounce";
import MainTable from "../MainTable";
import moment from "moment";
import "moment/locale/th";
import FactoryDetailModal from "./components/FactoryDetailModal";

moment.locale("th");

const STATUS_MAP_TO_OLD_VERSION = {
    pending: "pending",
    edited: "edit",
    disapproved: "notApproved",
    approved: "approved",
};

const COLORS_MAP_TYPE_COMPANY = {
    นำเข้า: "orange",
    ผลิต: "green",
    ผลิตและนำเข้า: "blue",
};

const HomeV2 = () => {
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const [active_menu, setActiveMenu] = useState(searchParams.get("tab") || "pending");
    const [search_text, setSearchText] = useState(searchParams.get("search") || "");
    const [search_check, setSearchCheck] = useState(searchParams.get("search") || "");
    const [page, setPage] = useState(searchParams.get("page") || 1);
    const [rows_per_page, setRowsPerPage] = useState(searchParams.get("row") || 10);

    const [data, setData] = useState([]);
    const [info, setInfo] = useState({});
    const [is_loading, setIsLoading] = useState(false);
    const [select_company, setSelectCompany] = useState({});
    const [is_open_pdpa_modal, setIsOpenPDPAModal] = useState(false);
    const [is_open_detail_modal, setIsOpenDetailModal] = useState(false);

    const fnCheckRequestPDPA = async () => {
        try {
            const { data } = await Axios.get(GET_STATUS_PDPA, HEADER_TOKEN());
            if (!data.value.consented) {
                setIsOpenPDPAModal(true);
            }
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                history.push("/oie");
            }
        }
    };

    const fnHandleSubmitConfirmPDPA = async () => {
        try {
            await Axios.post(UPDATE_STATUS_REQUEST_PDPA, null, HEADER_TOKEN());
            setIsOpenPDPAModal(false);
        } catch (error) {
            Swal.fire({
                type: "error",
                title: "เกิดข้อผิดพลาด...",
            });
        }
    };

    const fnHandleExportExcelCompanyApprove = async (companies_id, company_name, file_type) => {
        await Axios.get(`${OIE_EXPORT_COMPANY_APPROVE}/${companies_id}/${file_type}`, TOKEN_BLOB)
            .then((response) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute("download", company_name + ".xlsx"); //any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.error("error export excel");
            });
    };

    const fnHandleChangeMenu = (_, { name }) => {
        setActiveMenu(name);
        setPage(1);
    };

    const fnHandleChangePage = (newPage) => {
        setPage(newPage);
    };

    const fnHandleChangeRowsPerPage = (value) => {
        setPage(1);
        setRowsPerPage(value);
    };

    const fnSearchDebounce = useCallback(
        debounce((value) => {
            setSearchText(value);
            setPage(1);
        }, 1000),
        []
    );

    const fnFetchData = async () => {
        setIsLoading(true);
        const queryStaring = new URLSearchParams({
            search: search_text,
            page,
            row: rows_per_page,
            tab: active_menu,
        });
        history.push(`/oie/entrepreneur?${queryStaring}`);
        try {
            const { data } = await Axios.get(`${OIE_COMPANY_LIST}?${queryStaring}`, HEADER_TOKEN());

            setData(data?.data.oie_company_list);
            setInfo(data?.data.info);
            setIsLoading(false);
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                history.push("/oie");
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fnCheckRequestPDPA();
    }, []);

    useEffect(() => {
        fnFetchData();
    }, [active_menu, search_text, page, rows_per_page]);

    const columns = [
        {
            label: "ลำดับ",
            styleHead: {
                width: 50,
                minWidth: 50,
                whiteSpace: "nowarp",
            },
            style: {
                textAlign: "center",
            },
            format: ({ index }) => rows_per_page * (page - 1) + (index + 1),
        },
        {
            label: "บริษัท",
            styleHead: {
                width: 150,
                minWidth: 150,
                whiteSpace: "nowrap",
            },
            style: {
                textAlign: "left",
                whiteSpace: "nowrap",
            },
            showOnTab: ["pending", "edited", "disapproved"],
            format: ({ data }) => (
                <Link
                    to={`/oie/detailCompany/${data.companies_id}/${STATUS_MAP_TO_OLD_VERSION[active_menu]}/${
                        data.type_registration ? data.type_registration : ""
                    }`}
                >
                    {data.company_name}
                </Link>
            ),
        },
        {
            label: "Reference Code",
            id: "reference_code",
            styleHead: {
                whiteSpace: "nowrap",
                width: 150,
                minWidth: 150,
            },
            style: {
                textAlign: "center",
                whiteSpace: "nowrap",
            },
            showOnTab: ["approved"],
        },
        {
            label: "บริษัท",
            styleHead: {
                whiteSpace: "nowrap",
            },
            style: {
                textAlign: "center",
                whiteSpace: "nowrap",
            },
            showOnTab: ["approved"],
            format: ({ data }) => (
                <div style={{ display: "flex" }}>
                    <Link
                        to={`/oie/detailCompany/${data.companies_id}/${STATUS_MAP_TO_OLD_VERSION[active_menu]}/${
                            data.type_registration ? data.type_registration : ""
                        }`}
                    >
                        {data.company_name}
                    </Link>
                    <div style={{ width: "75px" }}>
                        <Button
                            circular
                            size="mini"
                            color="green"
                            icon="file excel"
                            onClick={() => {
                                fnHandleExportExcelCompanyApprove(data.companies_id, data.company_name, "excel");
                            }}
                        />
                    </div>
                </div>
            ),
        },
        {
            label: "ประเภท",
            styleHead: {
                width: 120,
                minWidth: 120,
                whiteSpace: "nowrap",
            },
            style: {
                textAlign: "center",
                whiteSpace: "nowrap",
            },
            format: ({ data }) => (
                <Label
                    color={COLORS_MAP_TYPE_COMPANY[data.company_type] || "grey"}
                    horizontal
                    onClick={() => {
                        setIsOpenDetailModal(true);
                        setSelectCompany({ id: data.companies_id, name: data.company_name, type: data.company_type });
                    }}
                    style={{ cursor: "pointer" }}
                >
                    {data?.company_type || "-"}
                </Label>
            ),
        },
        {
            label: "ที่อยู่",
            id: "company_address",
            styleHead: {
                width: 200,
                minWidth: 200,
                whiteSpace: "nowrap",
            },
        },
        {
            label: "ผู้ประสานงาน",
            id: "person_name",
            styleHead: {
                width: 150,
                minWidth: 150,
                whiteSpace: "nowrap",
            },
            style: {
                textAlign: "center",
                whiteSpace: "nowrap",
            },
        },
        {
            label: "เบอร์โทรศัพท์",
            id: "person_phone",
            styleHead: {
                width: 100,
                minWidth: 100,
                whiteSpace: "nowrap",
            },
            style: {
                textAlign: "center",
                whiteSpace: "nowrap",
            },
            showOnTab: ["pending", "edited", "disapproved"],
        },
        {
            label: "อีเมล",
            id: "person_email",
            styleHead: {
                width: 150,
                minWidth: 150,
                whiteSpace: "nowrap",
            },
            style: {
                textAlign: "center",
                whiteSpace: "nowrap",
            },
        },
        {
            label: "วันที่ลงทะเบียน",
            styleHead: {
                width: 100,
                minWidth: 100,
                whiteSpace: "nowrap",
            },
            style: {
                textAlign: "center",
            },
            format: ({ data }) => {
                const date_format = data.date_activity ? moment(data.date_activity).add(543, "years").format("DD/MM/YYYY") : "-";
                return <div>{data.date_activity ? date_format : "-"}</div>;
            },
        },
        {
            label: "สถานะ",
            id: "approve",
            styleHead: {
                width: 100,
                minWidth: 100,
                whiteSpace: "nowrap",
            },
            style: {
                textAlign: "center",
                whiteSpace: "nowrap",
            },
        },
        {
            label: "วันที่ส่งแก้ไข",
            styleHead: {
                width: 100,
                minWidth: 100,
                whiteSpace: "nowrap",
            },
            style: {
                textAlign: "center",
            },
            showOnTab: ["edited"],
            format: ({ data }) => {
                const date_format = data.edit_oie_at ? moment(data.edit_oie_at).add(543, "years").format("DD/MM/YYYY") : "-";
                return <div>{date_format}</div>;
            },
        },
        {
            label: "วันที่ไม่อนุมัติ",
            styleHead: {
                width: 100,
                minWidth: 100,
                whiteSpace: "nowrap",
            },
            style: {
                textAlign: "center",
            },
            showOnTab: ["disapproved"],
            format: ({ data }) => {
                const date_format = data.unapprove_oie_at ? moment(data.unapprove_oie_at).add(543, "years").format("DD/MM/YYYY") : "-";
                return <div>{date_format}</div>;
            },
        },
        {
            label: "วันที่อนุมัติ",
            styleHead: {
                width: 100,
                minWidth: 100,
                whiteSpace: "nowrap",
            },
            style: {
                textAlign: "center",
            },
            showOnTab: ["approved"],
            format: ({ data }) => {
                const date_format = data.approve_oie_at ? moment(data.approve_oie_at).add(543, "years").format("DD/MM/YYYY") : "-";
                return <div>{date_format}</div>;
            },
        },
        {
            label: "วันที่แก้ไขข้อมูลล่าสุด",
            styleHead: {
                width: 100,
                minWidth: 100,
                whiteSpace: "nowrap",
            },
            style: {
                textAlign: "center",
            },
            showOnTab: ["approved"],
            format: ({ data }) => {
                const date_format = data.last_approve_edited_data_oie_at
                    ? moment(data.last_approve_edited_data_oie_at).add(543, "years").format("DD/MM/YYYY")
                    : "-";
                return <div>{date_format}</div>;
            },
        },
    ];

    return (
        <div>
            <Modals.PDPARequestForm
                open={is_open_pdpa_modal}
                callback={fnHandleSubmitConfirmPDPA}
                onClose={() => setIsOpenPDPAModal(false)}
            />
            <div style={{ margin: "30px 10px" }}>
                <Container fluid>
                    <Header
                        as="h2"
                        textAlign="left"
                    >
                        <Icon
                            name="users"
                            color="violet"
                        />
                        ข้อมูลผู้ประกอบการ
                    </Header>
                    <Divider />
                    <Grid>
                        <Grid.Row only="mobile">
                            <Grid.Column>
                                <Menu
                                    fluid
                                    vertical
                                    attached="top"
                                    icon="labeled"
                                    color="violet"
                                    size="small"
                                >
                                    <Menu.Item
                                        name="pending"
                                        content="รอการอนุมัติ"
                                        icon="wait"
                                        active={active_menu === "pending"}
                                        onClick={fnHandleChangeMenu}
                                    />
                                    <Menu.Item
                                        name="edited"
                                        content="แก้ไข"
                                        icon="edit"
                                        active={active_menu === "edited"}
                                        onClick={fnHandleChangeMenu}
                                    />
                                    <Menu.Item
                                        name="disapproved"
                                        content="ไม่ผ่านการอนุมัติ"
                                        icon="times"
                                        active={active_menu === "disapproved"}
                                        onClick={fnHandleChangeMenu}
                                    />
                                    <Menu.Item
                                        name="approved"
                                        content="อนุมัติ"
                                        icon="check"
                                        active={active_menu === "approved"}
                                        onClick={fnHandleChangeMenu}
                                    />
                                    <Menu.Menu position="right" />
                                    <Menu.Item>
                                        <div
                                            style={{
                                                margin: "10px 0",
                                            }}
                                        >
                                            <Input
                                                floated="right"
                                                icon="search"
                                                placeholder="Search..."
                                                value={search_check}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const regex = /^[^-\s]/;
                                                    if (!regex.test(value) && value.length > 0) {
                                                        setSearchCheck("");
                                                    } else {
                                                        setSearchCheck(e.target.value);
                                                        fnSearchDebounce(e.target.value);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Menu.Item>
                                </Menu>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row only="tablet computer">
                            <Grid.Column>
                                <Menu
                                    fluid
                                    attached="top"
                                    icon="labeled"
                                    tabular
                                    color="violet"
                                    size="small"
                                >
                                    <Menu.Item
                                        name="pending"
                                        content="รอการอนุมัติ"
                                        icon="wait"
                                        active={active_menu === "pending"}
                                        onClick={fnHandleChangeMenu}
                                    />

                                    <Menu.Item
                                        name="edited"
                                        content="แก้ไข"
                                        icon="edit"
                                        active={active_menu === "edited"}
                                        onClick={fnHandleChangeMenu}
                                    />
                                    <Menu.Item
                                        name="disapproved"
                                        content="ไม่ผ่านการอนุมัติ"
                                        icon="times"
                                        active={active_menu === "disapproved"}
                                        onClick={fnHandleChangeMenu}
                                    />
                                    <Menu.Item
                                        name="approved"
                                        content="อนุมัติ"
                                        icon="check"
                                        active={active_menu === "approved"}
                                        onClick={fnHandleChangeMenu}
                                    />
                                    <Menu.Menu position="right" />
                                    <Menu.Item>
                                        <div
                                            style={{
                                                margin: "10px 0",
                                            }}
                                        >
                                            <Input
                                                floated="right"
                                                icon="search"
                                                placeholder="Search..."
                                                value={search_check}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const regex = /^[^-\s]/;
                                                    if (!regex.test(value) && value.length > 0) {
                                                        setSearchCheck("");
                                                    } else {
                                                        setSearchCheck(e.target.value);
                                                        fnSearchDebounce(e.target.value);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Menu.Item>
                                </Menu>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <MainTable
                        data={data || []}
                        columns={columns}
                        activeMenu={active_menu}
                        loading={is_loading}
                    />
                    <div style={{ alignSelf: "flex-end", flex: 1, padding: "10px 0px" }}>
                        <OiePagination
                            handleChangePage={fnHandleChangePage}
                            totalPages={info?.total_pages}
                            page={parseInt(page)}
                            handleChangeRowsPerPage={fnHandleChangeRowsPerPage}
                            rowsPerPage={parseInt(rows_per_page)}
                            totalRows={info?.total_rows}
                        />
                    </div>
                </Container>
                <FactoryDetailModal
                    open={is_open_detail_modal}
                    company={select_company}
                    onClose={() => {
                        setIsOpenDetailModal(false);
                        setSelectCompany("");
                    }}
                />
            </div>
        </div>
    );
};

export default HomeV2;
