import React, { Component } from 'react';
import { Segment, Divider, Header, Icon, Container, Form, Grid, Button } from 'semantic-ui-react';
import { Menu } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'; // connect redux
// import EntrepreneurPreview from './previewEdit/EditEntrepreneurPreview'
// import PreviewProductRegister from './previewEdit/EditFactoryManufacturePreview';
// import ProductImportPreview from './previewEdit/EditFactoryImportPreview';
// import PersonAuthorizePreview from './previewEdit/EditAuthorizedPersonPreview';
// import AddDelegatePreview from './previewEdit/EditDelegatePersonPreview'
import EntrepreneurPreview from '../registerControl/previewRegister/entrepreneurPreview'
import PreviewProductRegister from '../registerControl/previewRegister/previewProductRegister';
import ProductImportPreview from '../registerControl/previewRegister/productImportPreview';
import PersonAuthorizePreview from '../registerControl/previewRegister/personAuthorizePreview';
import AddDelegatePreview from '../registerControl/previewRegister/addDelegatePreview'
import axios from 'axios'
import { SEND_EDIT_DATA_ENTREPRENEUR } from '../router';
import Swal from 'sweetalert2'
import Headers from '../header/requestHeader';

const nationality = [{ key: 1, text: 'ไทย', value: 1 }, { key: 2, text: 'ต่างชาติ', value: 2 }];

class registerConfirm extends Component {
  constructor() {
    super();
    this.state = {
      redirect: false,
      redirectConfirm: false,
      entrepreneur_type_text: "",
      menu_entrepreneur: true,
      menu_productRegister: false,
      menu_productImport: false,
      menu_authorized: false,
      menu_delegate: false,
      table_entrepreneur: true,
      table_productRegister: false,
      table_productImport: false,
      table_authorized: false,
      table_delegate: false,
      entrepreneurPathFile: "",
      delegatePathFile: "",
      data_personal: false,
      data_juristic: false,
      data_limited_partnershipTH: false,
      data_company_limited: false,
      data_public_company: false,
      activeItem: 'ข้อมูลผู้ประกอบการ', //set defuat value activeItem to check booleen set menu active,
      goBackPath: "/company/editEntrepreneur/authorizedPerson",

      iframe: "",
      loading: false
    }
  }

  componentWillMount() {
    //Table Entrepreneur Check Value
    // this.setState({})
    this.setState({ stateRedux: this.props.stateRedux })
    if (this.props.stateRedux.entrepreneurFields.personality_value === 1) {
      this.setState({
        data_personal: true,
      })
    } else if (this.props.stateRedux.entrepreneurFields.personality_value === 2) {
      this.setState({
        data_juristic: true
      })
    } else if (this.props.stateRedux.entrepreneurFields.personality_value === 3) {
      this.setState({
        data_juristic: true
      })
    } else if (this.props.stateRedux.entrepreneurFields.personality_value === 4) {
      this.setState({
        data_juristic: true
      })
    } else if (this.props.stateRedux.entrepreneurFields.personality_value === 5) {
      this.setState({
        data_juristic: true
      })
    }
  }


  // Menu Control
  state = { activeItem: 'bio' }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name })
    if (name === 'ข้อมูลผู้ประกอบการ') {
      this.setState({
        table_entrepreneur: true,
        table_productRegister: false,
        table_productImport: false,
        table_authorized: false,
        table_delegate: false,
      })
    }

    if (name === 'ข้อมูลการผลิต') {
      this.setState({
        table_entrepreneur: false,
        table_productRegister: true,
        table_productImport: false,
        table_authorized: false,
        table_delegate: false,
      })
    }

    if (name === 'ข้อมูลการนำเข้า') {
      this.setState({
        table_entrepreneur: false,
        table_productRegister: false,
        table_productImport: true,
        table_authorized: false,
        table_delegate: false,
      })
    }

    if (name === 'ข้อมูลผู้มีอำนาจลงนาม') {
      this.setState({
        table_entrepreneur: false,
        table_productRegister: false,
        table_productImport: false,
        table_authorized: true,
        table_delegate: false,
      })
    }

    if (name === 'ผู้รับมอบอำนาจ') {
      this.setState({
        table_entrepreneur: false,
        table_productRegister: false,
        table_productImport: false,
        table_authorized: false,
        table_delegate: true,
      })
    }
  }

  componentDidMount() {
    //Menu bar State
    // console.log('entre', this.props.stateRedux)
    if (this.props.stateRedux.status_entrepreneur === true) {
      this.setState({
        menu_entrepreneur: true,
        table_entrepreneur: true,
      })
    }

    if (this.props.stateRedux.status_productRegister === true) {
      this.setState({
        menu_productRegister: true,
      })
    }

    if (this.props.stateRedux.status_productImport === true) {
      this.setState({
        menu_productImport: true,
      })
    }

    if (this.props.stateRedux.status_authorized === true) {
      this.setState({
        menu_authorized: true,
      })
    }

    if (this.props.stateRedux.status_delegate === true) {
      this.setState({
        menu_delegate: true,
      })
    }


    //
    if (this.props.stateRedux.addDelegate === true) {
      this.setState({ goBackPath: "/company/editEntrepreneur/delegatePerson" })
    } else {
      this.setState({ goBackPath: "/company/editEntrepreneur/authorizedPerson" })
    }


  }

  appendEntrepreneur = formData => {
    let entrepreneurFields = this.props.stateRedux.entrepreneurFields
    // console.log('entr',entrepreneurFields)
    formData.append('companies_id', this.props.stateRedux.companies_id)
    formData.append('last_edited_at', this.props.stateRedux.last_edited_at)
    formData.append('type_companies_id', entrepreneurFields.personality_value)
    if (entrepreneurFields.personality_value === 1) {
      formData.append('name_th', `${entrepreneurFields.name_prefixTH_text} ${entrepreneurFields.personal_nameTH}`)
      formData.append('name_en', `${entrepreneurFields.name_prefixEN_text} ${entrepreneurFields.personal_nameEN}`)
    } else if (entrepreneurFields.personality_value === 2) {
      formData.append('name_th', entrepreneurFields.name_juristicTH)
      formData.append('name_en', entrepreneurFields.name_juristicEN)
    } else if (entrepreneurFields.personality_value === 3) {
      formData.append('name_th', entrepreneurFields.name_limited_partnershipTH)
      formData.append('name_en', entrepreneurFields.name_limited_partnershipEN)
    } else if (entrepreneurFields.personality_value === 4) {
      formData.append('name_th', entrepreneurFields.name_company_limitedTH)
      formData.append('name_en', entrepreneurFields.name_company_limitedEN)
    } else if (entrepreneurFields.personality_value === 5) {
      formData.append('name_th', entrepreneurFields.name_public_companyTH)
      formData.append('name_en', entrepreneurFields.name_public_companyEN)
    }
    formData.append('manufacture', entrepreneurFields.status_manufacture)
    formData.append('import', entrepreneurFields.status_import)
    formData.append('tax_id', entrepreneurFields.tax_id)
    formData.append('address', entrepreneurFields.address_id)
    formData.append('soi', entrepreneurFields.alley)
    formData.append('road', entrepreneurFields.road)
    formData.append('subdistrict', entrepreneurFields.sub_district)
    formData.append('district', entrepreneurFields.district)
    formData.append('province', entrepreneurFields.province)
    formData.append('zipcode', entrepreneurFields.zip_code)
    formData.append('phone', entrepreneurFields.phone_number)
    formData.append('fax', entrepreneurFields.fax_number)
    formData.append('website', entrepreneurFields.website)
    formData.append('certificatedoc', entrepreneurFields.file_corporation_certificate.data)
    formData.append('taxdoc', entrepreneurFields.file_vat_certificate.data)
    formData.append('trademarkdoc', entrepreneurFields.file_trademark.data)
    formData.append('trademark', entrepreneurFields.tradeMark_id)
  }

  appendFactory = formData => {

    var indexFactorytype1 = 0
    // แปลงค่าจาก remark array เป็น formData


    const remarksImport = this.props.stateRedux.remarks_import;
    const remarks = this.props.stateRedux.remarks;
if (remarksImport || (Array.isArray(remarksImport) && remarksImport.length > 0)) {
remarksImport.forEach((item) => {
formData.append('remove_factory_import_id', item.id);
formData.append('remove_factory_import_remark', item.remark);
});
}

if (remarks || (Array.isArray(remarks) && remarks.length > 0)) {
remarks.forEach((item) => {
formData.append('remove_factory_import_id', item.id);
formData.append('remove_factory_import_remark', item.remark);
});
}
// this.props.stateRedux.remarks.forEach((item, index) => {
//   formData.append(`remove_factory_id`, item.id);
//   formData.append(`remove_factory_remark`, item.remark);
// });
//     this.props.stateRedux.remarks_import.forEach((item, index) => {
//   formData.append(`remove_factory_import_id`, item.id);
//   formData.append(`remove_factory_import_remark`, item.remark);
// });
    this.props.stateRedux.factory_form_obj.map((factory_form, indexFactory) => {
      if (this.props.stateRedux.productRegister) {
        formData.append('factories_id', factory_form.factories_id)
        formData.append('type_factories_id', '1')
        formData.append('factory_name_th', factory_form.factory_name_th)
        formData.append('factory_name_en', factory_form.factory_name_en)
        formData.append('license_no', factory_form.factory_license_number)
        formData.append('standard_qc_id', factory_form.factory_certificate)
        formData.append('standard_qc_expire', factory_form.factory_expire_certificate)
        formData.append('standard_from', factory_form.factory_from)
        formData.append('standard_department', factory_form.factory_under)
        formData.append('factories_area_id', factory_form.posIndustrial.pos)
        formData.append('factory_address', factory_form.factory_number)
        formData.append('factory_soi', factory_form.factory_alley)
        formData.append('factory_road', factory_form.factory_road)
        formData.append('factory_subdistrict', factory_form.factory_zone)
        formData.append('factory_district', factory_form.factory_district)
        formData.append('factory_province', factory_form.factory_province)
        formData.append('factory_zipcode', factory_form.factory_zip_code)
        formData.append('phone', factory_form.factory_tel)
        formData.append(`factory_license${indexFactory}`, factory_form.factory_license_file.data)
        formData.append(`qc_license${indexFactory}`, factory_form.factory_certificate_file.data)
        factory_form.factory_product.map((factory_product, indexProduct) => {
          formData.append(`product_id${indexFactory}`, factory_product.product_id)
          formData.append(`type_product_id${indexFactory}`, factory_product.product_name)
          formData.append(`type_struct_id${indexFactory}`, factory_product.product_structure)
          formData.append(`product_amount${indexFactory}`, factory_product.product_power_output)
          factory_product.product_trade_name.map((product_trade_name) => {
            formData.append(`brand_id${indexFactory}${indexProduct}`, product_trade_name.brand_id)
            formData.append(`name_brand${indexFactory}${indexProduct}`, product_trade_name.trade_name)
          })
        })
        indexFactorytype1++
      }
    })

    this.props.stateRedux.formProductImport.map((form, indexFactory) => {
      if (this.props.stateRedux.productImport) {
        formData.append('factories_id', form.factory.factories_id)
        formData.append('type_factories_id', '2')
        formData.append('factory_name_th', form.factory.nameFactory)
        formData.append('standard_qc_id', form.factory.certificate)
        formData.append('standard_qc_expire', form.factory.expire_certificate)
        formData.append('standard_from', form.factory.from)
        formData.append('standard_department', form.factory.under)
        formData.append('text_address', form.factory.location)
        formData.append('country_id', form.factory.country)
        formData.append(`qc_license${indexFactory + indexFactorytype1}`, form.factory.file_doc)
        form.productImport.map((productImport, indexProduct) => {
          formData.append(`product_id${indexFactory + indexFactorytype1}`, productImport.product_id)
          formData.append(`type_product_id${indexFactory + indexFactorytype1}`, productImport.productType)
          formData.append(`type_struct_id${indexFactory + indexFactorytype1}`, productImport.productStructure)
          formData.append(`product_amount${indexFactory + indexFactorytype1}`, productImport.importCapacity)
          productImport.productName.map((productName) => {
            formData.append(`brand_id${indexFactory + indexFactorytype1}${indexProduct}`, productName.brand_id)
            formData.append(`name_brand${indexFactory + indexFactorytype1}${indexProduct}`, productName.name)
          })
        })
        form.exportPerson.map((exportPerson) => {
          formData.append(`export_person_id${indexFactory}`, exportPerson.export_person_id)
          formData.append(`export_person_name${indexFactory}`, exportPerson.exportPersonName)
          formData.append(`export_country_id${indexFactory}`, exportPerson.exportPersonCountry)
        })
        form.warehouse.map((warehouse) => {
          formData.append(`factory_import_id${indexFactory}`, warehouse.factory_import_id)
          formData.append(`factory_import_address${indexFactory}`, warehouse.address_id)
          formData.append(`factory_import_soi${indexFactory}`, warehouse.alley)
          formData.append(`factory_import_road${indexFactory}`, warehouse.road)
          formData.append(`factory_import_subdistrict${indexFactory}`, warehouse.sub_district)
          formData.append(`factory_import_district${indexFactory}`, warehouse.district)
          formData.append(`factory_import_province${indexFactory}`, warehouse.province)
          formData.append(`factory_import_zipcode${indexFactory}`, warehouse.zip_code)
        })
      }
    })
  }

  appendPerson = formData => {
    let indexFile = 0;
    this.props.stateRedux.personAuthorized.map((person, index) => {
      formData.append('person_id', person.person_id)
      formData.append('type_person', 1)
      formData.append('nationality', nationality[person.nationality - 1].text)
      formData.append('person_name_th', `${person.name_prefixTH_text} ${person.nameTH}`)
      formData.append('person_name_en', `${person.name_prefixEN_text} ${person.nameEN}`)

      if (person.nationality === 1) {
        formData.append('person_tax_id', person.idNumber)
        formData.append('person_address', person.address_id)
        formData.append('person_soi', person.alley)
        formData.append('person_road', person.road)
        formData.append('person_subdistrict', person.sub_district)
        formData.append('person_district', person.district)
        formData.append('person_province', person.province)
        formData.append('person_zipcode', person.zip_code)
        formData.append(`tax_id${index}`, person.file)
      } else {
        formData.append('person_passport', person.idNumber)
        formData.append('person_foreigner_address', person.address)
        formData.append(`passport${index}`, person.file)
      }
      formData.append('person_phone', person.phoneNumber)
      formData.append('email', person.email)
      indexFile = index
    })

    let delegateFields = this.props.stateRedux.delegateFields
    if (this.props.stateRedux.addDelegate) {
      formData.append('person_id', this.props.stateRedux.delegateFields_id)
      formData.append('type_person', 2)
      formData.append('nationality', delegateFields.nationality_text)
      formData.append('person_name_th', `${delegateFields.name_prefixTH_text} ${delegateFields.delegate_nameTH}`)
      formData.append('person_name_en', `${delegateFields.name_prefixEN_text} ${delegateFields.delegate_nameEN}`)
      if (delegateFields.nationality_value === 1) {
        formData.append('person_tax_id', delegateFields.delegate_id_number)
        formData.append('person_address', delegateFields.address_id)
        formData.append('person_soi', delegateFields.alley)
        formData.append('person_road', delegateFields.road)
        formData.append('person_subdistrict', delegateFields.sub_district)
        formData.append('person_district', delegateFields.district)
        formData.append('person_province', delegateFields.province)
        formData.append('person_zipcode', delegateFields.zip_code)
        formData.append(`tax_id${indexFile + 1}`, delegateFields.file_idcard.data)
      } else {
        formData.append('person_passport', delegateFields.delegate_passport_number)
        formData.append('person_foreigner_address', delegateFields.foreigner_address)
        formData.append(`passport${indexFile + 1}`, delegateFields.file_passport.data)
      }
      formData.append('power_of_attorney', delegateFields.file_poa.data)
      formData.append('person_phone', delegateFields.delegate_phone_number)
      formData.append('email', delegateFields.delegate_email)
    }
  }


  registerForm = () => {
    Swal.fire({
      title: 'ยืนยันการแก้ไขหรือไม่',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.value) {
        this.setState({ loading: true })
        let formData = new FormData()
        this.appendEntrepreneur(formData)
        this.appendFactory(formData)
        this.appendPerson(formData)
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }
        axios.post(`${SEND_EDIT_DATA_ENTREPRENEUR}`,
          formData, {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }).then((response) => {
          if (response.data.status === 'Successed') {
            this.setState({ loading: false })
            Swal.fire({
              position: 'center',
              type: 'success',
              title: 'แก้ไขสำเร็จ',
              showConfirmButton: false,
              timer: 1500
            })
            this.setState({ success: true })
          } else {
            this.setState({ loading: false })
            Swal.fire({
              position: 'center',
              type: 'error',
              title: 'แก้ไขไม่สำเร็จ',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }).catch((error) => {
          this.setState({ loading: false })
          let textAlert
          if (error.response.data.message === 'Waiting for authorization') {
            textAlert = 'คุณได้ขอยื่นแก้ไขไปแล้ว โปรดรอผลการอนุมัติ'
          } else {
            textAlert = ''
          }
          Swal.fire({
            title: 'แก้ไขไม่สำเร็จ',
            text: textAlert,
            type: 'error',
            confirmButtonText: 'กลับไปหน้าหลัก',
          }).then((result) => {
            if (result.value) {
              this.props.history.push('/company/main')
            }
          })
        })
      }
    })
  }

  render() {
    const { activeItem } = this.state
    if (this.state.success) {
      return <Redirect to='/company/editEntrepreneur/editSuccess' />
    }
    return (
      <div className="App">
        <Headers />
        <Container >
          <Form loading={this.state.loading}>
            <br />
            <Segment size='tiny' padded='very' color='violet'>
              <Header>
                <h1><Icon color='violet' name='exclamation circle' />ตรวจสอบความถูกต้องของข้อมูล</h1>
              </Header>
              <Divider></Divider>
              <br />
              <div>
                <Menu size='large' pointing secondary>
                  <Menu.Item

                    name='ข้อมูลผู้ประกอบการ'
                    active={activeItem === 'ข้อมูลผู้ประกอบการ'}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item
                    style={{ display: this.props.stateRedux.productRegister ? 'block' : 'none' }}
                    name='ข้อมูลการผลิต'
                    active={activeItem === 'ข้อมูลการผลิต'}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item
                    style={{ display: this.props.stateRedux.productImport ? 'block' : 'none' }}
                    name='ข้อมูลการนำเข้า'
                    active={activeItem === 'ข้อมูลการนำเข้า'}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item

                    name='ข้อมูลผู้มีอำนาจลงนาม'
                    active={activeItem === 'ข้อมูลผู้มีอำนาจลงนาม'}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item
                    style={{ display: this.props.stateRedux.addDelegate ? 'block' : 'none' }}
                    name='ผู้รับมอบอำนาจ'
                    active={activeItem === 'ผู้รับมอบอำนาจ'}
                    onClick={this.handleItemClick}
                  />
                </Menu>
              </div>
              <br />
              <br />


              {/* ############################################################ */}
              <Form.Field style={{ display: this.state.table_entrepreneur ? 'block' : 'none' }}>
                <EntrepreneurPreview />
              </Form.Field>

              {/* Product Register ########################################################################### */}
              <Form.Field style={{ display: this.state.table_productRegister ? 'block' : 'none' }}>
                <PreviewProductRegister />
              </Form.Field>

              {/* Product Import ############################################################# */}
              <Form.Field style={{ display: this.state.table_productImport ? 'block' : 'none' }}>
                <ProductImportPreview />
              </Form.Field>


              {/* Add Authorized ############################################################# */}
              <Form.Field style={{ display: this.state.table_authorized ? 'block' : 'none' }}>
                <PersonAuthorizePreview />
              </Form.Field>

              {/* ############################################################# */}
              <Form.Field style={{ display: this.state.table_delegate ? 'block' : 'none' }}>
                <AddDelegatePreview />
              </Form.Field>
            </Segment>


            <Button icon labelPosition='left' color='green' floated='right' onClick={this.registerForm}>
              ยื่นคำขอแก้ไข
            <Icon name='right arrow' />
            </Button>
            <Link to={this.state.goBackPath}>
              {/* <Link to="/entrepreneurRegister"> */}
              <Button
                icon
                labelPosition='left'
                floated='right'
              >
                ยกเลิกการลงทะบียน
              <Icon name='left arrow' />
              </Button>
            </Link>
            <br />
            <br />
            <br />
            <br />
          </Form>
        </Container>
      </div>
    );
  }
}
// export default registerConfirm;
const mapStateToProps = function (state) {
  return {
    message: 'This is message from mapStateToProps',
    stateRedux: state.reduxxx
  }
}

export default connect(mapStateToProps)(registerConfirm);